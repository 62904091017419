import { Component, OnInit, Input } from '@angular/core';
import { User } from '../models/user';
import { UserService } from '../service/user.service';
import {Registro} from '../models/user';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { DetalleService } from '../service/detalle.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-modal-sesiones',
  templateUrl: './modal-sesiones.component.html',
  styleUrls: ['./modal-sesiones.component.css']
})
export class ModalSesionesComponent implements OnInit {

  @Input()
  log: String;
  @Input()
  GoTo: String;

  isDisabled: boolean = false;

  showPassword:boolean = false;
  showPassword1:boolean = false;
  showPassword2:boolean = false;

  private logear:boolean;
  private regyster: boolean;

  //para logear usuario
  private user:User;
  private mensaje:String;
  private isCorrect:boolean;

  //Para registrar usuarios
  public registro = new Registro();

  public estados;
  public paises;
  private token = localStorage.getItem('token');
  public language;
  public numbers;

  constructor(private userService: UserService, private detalleService: DetalleService, private router: Router, private translateS: TranslateService){
    this.numbers = Array(72).fill(1).map((x,i)=>i); 
    this.detalleService.catPais(this.token).subscribe((response: any) => {
      this.paises = response;
    });
    this.detalleService.catState(this.token).subscribe((response: any) => {
      this.estados = response;
    });
  }


  onSubmit(form: any) {
    Swal.showLoading();
    this.isDisabled = true;
    this.user.activar_licencia = 0;

    this.userService.login(this.user).subscribe((response: any) => {

      if (localStorage.getItem('token')) {
        this.language = localStorage.getItem('language');
        localStorage.clear();
        localStorage.setItem('language',this.language);
        this.router.navigate(['login']);
      }
      localStorage.setItem('token', response.token);
      let fechaLogin = new Date();
      let fechaAux = fechaLogin.toString();
      localStorage.setItem('fechaLogin', fechaAux);
      localStorage.setItem('email', this.user.email);
      Swal.close();
      
      if(this.GoTo == "B"){
        this.router.navigate(['blog']);
      }else if(this.GoTo == "T"){
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
        this.router.navigate(['tienda']));
      }else if(this.GoTo == "H"){
        this.router.navigate(['gracias_maestros']);
      }

    }, error => {
      this.isDisabled = false;
      Swal.fire({
        type: 'error',
        title: this.translateS.instant('login.popup_01'),
        showConfirmButton: false,
        timer: 1500
      });
      this.router.navigate(['login']);
      if (error.statusText === "Unauthorized") {
        this.mensaje = this.translateS.instant('login.popup_01');
        this.isCorrect = false;
        this.user.email = "";
        this.user.password = "";
      }
    });
  }

  crearUser(form:any){
    if (form.valid) {
      this.registro = form.value;
      this.registro.datacole = 0;
      this.registro.activar_licencia = 0;
      if(this.GoTo == "H") this.registro.txt_source = 'google';
       //this.registro.activar_licencia = (this.registro.codigo != "") ? 1 : 0;
      if ((this.registro.email == this.registro.emailConfir) && this.registro.email != ""
        && this.registro.email.match(/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/)) {
        if (this.registro.password != "" && (this.registro.password == this.registro.password_conf) && this.registro.password.length >= 6) {
          if (this.registro.aviso) {
            Swal.showLoading();
            this.isDisabled = true;
            this.userService.registro(this.registro).subscribe((response: any) => {
              localStorage.setItem('token', response.token);
              localStorage.setItem('email', this.registro.email);
              Swal.close();
              if (this.GoTo == "B") {
                this.router.navigate(['blog']);
              } else if (this.GoTo == "T") {
                this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                  this.router.navigate(['tienda']));
              }else if(this.GoTo == "H"){
                this.router.navigate(['gracias_maestros']);
              }

            }, error => {
              this.isDisabled = false;
              let msj = this.translateS.instant('register.error_reg_01');
              console.log(error);
              if (error["error"]["message"] == "El código es incorrecto.") {
                msj = this.translateS.instant('register.error_reg_02')
              }
              Swal.fire({
                type: 'error',
                title: msj,
                showConfirmButton: false,
                timer: 1500
              });
            });
          } else {
            Swal.fire({
              type: 'error',
              title: this.translateS.instant('register.error_reg_03'),
              showConfirmButton: false,
              timer: 1500
            });
          }
        } else {
          Swal.fire({
            type: 'error',
            title: this.translateS.instant('register.error_reg_04'),
            showConfirmButton: false,
            timer: 1500
          });
        }

      } else {
        Swal.fire({
          type: 'error',
          title: this.translateS.instant('register.error_reg_05'),
          showConfirmButton: false,
          timer: 1500
        });
      }
    } else {
      Swal.fire({
        type: 'error',
        title: this.translateS.instant('register.error_reg_06'),
        showConfirmButton: false,
        timer: 1500
      });
    }
 }

  clickRegistrar(){
    this.logear=false;
    this.regyster=true;
  }

  clickLogear(){
    this.logear=true;
    this.regyster=false;
  }

  ngOnInit() {
    if(this.log=='I'){
      this.logear = true;
      this.regyster = false;
    }else if(this.log=='R'){
      this.logear = false;
      this.regyster = true;
    }
    
    this.user = new User ('', '');
  }

}
