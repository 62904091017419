import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { Registro } from '../models/user';
import { UserService } from '../service/user.service';
declare var $: any;

@Component({
  selector: 'app-sylos',
  templateUrl: './sylos.component.html',
  styleUrls: ['./sylos.component.css']
})
export class SylosComponent implements OnInit {
  
  public registro= new Registro();
  public section = '';
  public banner = 0;
  public showBlock = false;
  public closeLogin = false;
  public RoI = 'R';
  public GoTo="H";
  public copys={
    title: '',
    content: ''
  };

  constructor(private userService: UserService,  private activatedRoute: ActivatedRoute, private router: Router, private location: Location) { 
    this.section = this.location.path().split('?')[0];
    this.userService.getCopy(this.section).subscribe((response) =>{
      this.copys.title = response[0].title;
      this.copys.content = response[0].content;
    });
    if(this.section.includes('seguros')) this.banner = 1;
    else if(this.section.includes('ninos')) this.banner = 2;
    else if(this.section.includes('aprender')) this.banner = 3;
    else if(this.section.includes('educativos')) this.banner = 4;
  }

  kill(element, id) {
    $(element).modal('hide');
    let iframe = document.getElementById(id) as HTMLIFrameElement;
    let src = $(iframe).attr('src');  
    $(iframe).attr('src', '').attr('src', src);
  }

  ngOnInit() {
  }

}
