import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './service/guard/auth-guard.service';

import { HomeComponent } from './home/home.component';
import { PerfilComponent } from './perfil/perfil.component';
import { GruposComponent } from './grupos/grupos.component';
import { ListadoComponent } from './listado/listado.component';
import { PlanesComponent } from './planes/planes.component';
import { NuevoPlanComponent } from './nuevo-plan/nuevo-plan.component';
import { PlanDetalleComponent } from './plan-detalle/plan-detalle.component';
import { ItemContenidoComponent } from './item-contenido/item-contenido.component';
import { ItemJuegoComponent } from './item-juego/item-juego.component';
import { LoginComponent } from './login/login.component';
import { EditarGrupoComponent } from './editar-grupo/editar-grupo.component';
import { ReportakComponent } from './reportak/reportak.component';
import { ReportakVideojuegosComponent } from './reportak-videojuegos/reportak-videojuegos.component';
import { ReportakGrupoComponent } from './reportak-grupo/reportak-grupo.component';
import { RegistroComponent } from './registro/registro.component';
import { InicioComponent } from './inicio/inicio.component';
import { VerJuegoComponent } from './ver-juego/ver-juego.component';
import { NewHomeComponent } from './new-home/new-home.component';
import { PlanPrediseComponent } from './plan-predise/plan-predise.component';
import { PlanpreDetalleComponent } from './planpre-detalle/planpre-detalle.component';
import { LandingComponent } from './landing/landing.component';
import { PlanSesionComponent } from './plan-sesion/plan-sesion.component';
import { BlogComponent } from './blog/blog.component';
import { ArticuloComponent } from './articulo/articulo.component';
import { ForoComponent } from './foro/foro.component';
import { ForoEntradaComponent } from './foro-entrada/foro-entrada.component';
import { PlanPublicasComponent } from './plan-publicas/plan-publicas.component';
import { PlanCompartidaComponent } from './plan-compartida/plan-compartida.component';
import { PlanPublicasDetalleComponent } from './plan-publicas-detalle/plan-publicas-detalle.component';
import { ReglasBlogyForoComponent } from './reglas-blogy-foro/reglas-blogy-foro.component';
import { RecibosPagosComponent } from './recibos-pagos/recibos-pagos.component';

import { TiendaComponent } from './tienda/tienda.component';
import { CapacitacionComponent } from './capacitacion/capacitacion.component';
import { CapacitacionListadoComponent } from './capacitacion-listado/capacitacion-listado.component';
import { CapacitacionWebinarComponent } from './capacitacion-webinar/capacitacion-webinar.component';
import { CapacitacionWebinarLiveComponent } from './capacitacion-webinar-live/capacitacion-webinar-live.component';
import { CapacitacionMoocComponent } from './capacitacion-mooc/capacitacion-mooc.component';
import { ZoomComponent } from './zoom/zoom.component';
import { MoocComponent } from './mooc/mooc.component';
import { RecuperaContraComponent } from './recupera-contra/recupera-contra.component';
import { SylosComponent } from './sylos/sylos.component';
import { SylosNinoComponent } from './sylos-nino/sylos-nino.component';
import { SylosGraciasComponent } from './sylos-gracias/sylos-gracias.component';

const routes: Routes = [
  { path: 'colombia', redirectTo: 'login/colombia' },
  { path: 'FIFYA', redirectTo: 'login/FIFYA' },
  { path: 'fifya', redirectTo: 'login/FIFYA' },
  { path: 'piloto', redirectTo: 'login/piloto' },
  { path: 'SEK', redirectTo: 'login/SEK' },
  { path: 'techint', redirectTo: 'login/techint' },
  { path: 'proed', redirectTo: 'login/proed' },
  { path: 'taktaktak', redirectTo: 'home/taktaktak' },
  { path: 'emgm', redirectTo: 'home/emgm' },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
//  { path: 'plan', component: HomeComponent, canActivate: [AuthGuardService],  data: {section: 'flag_planeacion'} },
  { path: 'perfil', component: PerfilComponent, canActivate: [AuthGuardService], data: {section: 'general'} },
  { path: 'grupos', component: GruposComponent, canActivate: [AuthGuardService], data: {section: 'grupos'} },
  { path: 'listado', component: ListadoComponent, canActivate: [AuthGuardService], data: {section: 'fichas'} },
//  { path: 'mis-planes', component: PlanesComponent, canActivate: [AuthGuardService],  data: {section: 'flag_planeacion'} },
  { path: 'login', component: LoginComponent },
  { path: 'recuperar-password/:id/:fecha', component: RecuperaContraComponent },
  { path: 'detalleplan/:slug/:id', component: PlanDetalleComponent, canActivate: [AuthGuardService], data: {section: 'flag_planeacion'} },
  { path: 'detallemodulo/:slug/:id', component: PlanpreDetalleComponent, canActivate: [AuthGuardService], data: {section: 'modulos_hab'} },
  { path: 'juego/:slug', component: ItemJuegoComponent, canActivate: [AuthGuardService], data: {section: 'fichas'} },
  { path: 'ver-juego/:slug', component: VerJuegoComponent, canActivate: [AuthGuardService], data: {section: 'fichas'} },
  // { path: 'recurso/:id', component: ItemContenidoComponent, canActivate: [AuthGuardService], data: {section: 'buscador'} },
  { path: 'editar-grupo/:id/:grado/:grupo', component: EditarGrupoComponent, canActivate: [AuthGuardService], data: {section: 'grupos'} },
  // { path: 'buscador', component: NuevoPlanComponent, canActivate: [AuthGuardService], data: {section: 'buscador'} },
  { path: 'reportak/alumnos', component: ReportakComponent, canActivate: [AuthGuardService], data: {section: 'reportak'} },
  { path: 'reportak/videojuegos', component: ReportakVideojuegosComponent, canActivate: [AuthGuardService], data: {section: 'reportak'} },
  { path: 'reportak/grupo', component: ReportakGrupoComponent, canActivate: [AuthGuardService], data: {section: 'reportak'} },
  // { path: 'buscador/:id', component: NuevoPlanComponent, canActivate: [AuthGuardService], data: {section: 'buscador'} },
  // { path: 'buscador/:id/:nivel/:materia', component: NuevoPlanComponent, canActivate: [AuthGuardService], data: {section: 'buscador'} },
  { path: 'registro', component: RegistroComponent },
  { path: 'home', component: InicioComponent },
  // { path: 'planeaciones-publicas', component: PlanPublicasComponent, data: {section: 'flag_planeacion'} },
  // { path: 'planeaciones-publicas/:slug/:id', component: PlanPublicasDetalleComponent, data: {section: 'flag_planeacion'} },
  // { path: 'planeaciones-compartidas/:slug/:id', component: PlanPublicasDetalleComponent, data: {section: 'flag_planeacion'} },
  // { path: 'planeaciones-compartidas', component: PlanCompartidaComponent, data: {section: 'flag_planeacion'} },
  { path: 'blog', component: BlogComponent, canActivate: [AuthGuardService], data: {section: 'blog'} }, 
  { path: 'blog/:id', component: ArticuloComponent, data: {section: 'blog'} },
  // { path: 'foro', component: ForoComponent, canActivate: [AuthGuardService], data: {section: 'foro'} },
  // { path: 'foro/:id', component: ForoEntradaComponent,  canActivate: [AuthGuardService], data: {section: 'foro'} },
  { path: 'inicio', component: NewHomeComponent, canActivate: [AuthGuardService], data: { section: 'general'} },
  { path: 'modulos-labtak', component: PlanPrediseComponent, canActivate: [AuthGuardService], data: {section: 'modulos_hab'} },
  { path: 'planeaciones-sesion', component: PlanSesionComponent, canActivate: [AuthGuardService], data: {section: 'flag_planeaciones_ses'} },
  // { path: 'tienda', component: TiendaComponent},
  { path: 'ReglasComunidad', component: ReglasBlogyForoComponent, canActivate: [AuthGuardService], data: {section: 'blog'}},
  // { path: 'recibo-de-pago', component: RecibosPagosComponent },
  // { path: 'capacitacion-docente', component: CapacitacionComponent, canActivate: [AuthGuardService], data: { section: 'flag_capacitacion' } },
  // { path: 'capacitacion-docente/:tipo', component: CapacitacionComponent, canActivate: [AuthGuardService], data: { section: 'flag_capacitacion' } },
  // { path: 'capacitacion-docente/webinar/:id', component: CapacitacionWebinarComponent, canActivate: [AuthGuardService], data: { section: 'flag_capacitacion' } },
  // { path: 'capacitacion-docente/webinar-live/:from/:id', component: CapacitacionWebinarLiveComponent, canActivate: [AuthGuardService], data: { section: 'flag_capacitacion' } },
  // { path: 'capacitacion-docente/webinar-live/:from/:id/:id_nav', component: CapacitacionWebinarLiveComponent, canActivate: [AuthGuardService], data: { section: 'flag_capacitacion' } },
  // { path: 'capacitacion-docente/mooc/:id', component: CapacitacionMoocComponent, canActivate: [AuthGuardService], data: { section: 'flag_capacitacion' } },
  // { path: 'capacitacion-docente/mooc-insc/:from/:id', component: MoocComponent, canActivate: [AuthGuardService], data: { section: 'flag_capacitacion' } },
  // { path: 'mi-capacitacion', component: CapacitacionListadoComponent, canActivate: [AuthGuardService], data: { section: 'flag_capacitacion' } },
  // { path: 'zoom', component: ZoomComponent },

  // { path: 'datacole', component: LandingComponent},

  /* Campañas*/
  
  { path: 'home/:campanias', component: InicioComponent },
  { path: 'login/:campanias', component: LoginComponent },
  { path: 'registro/:campanias', component: RegistroComponent },

  /* Sylos */
  { path: 'juegos-seguros', component: SylosComponent },
  { path: 'juegos-seguros/:juegos-seguros', component: SylosComponent },
  { path: 'juegos-seguros-en-linea', component: SylosComponent },
  { path: 'juegos-seguros-en-linea/:juegos-seguros-en-linea', component: SylosComponent },
  { path: 'juegos-seguros-por-internet', component: SylosComponent },
  { path: 'juegos-seguros-por-internet/:juegos-seguros-por-internet', component: SylosComponent },
  { path: 'juegos-seguros-para-ninos', component: SylosComponent },
  { path: 'juegos-seguros-para-ninos/:juegos-seguros-para-ninos', component: SylosComponent },
  { path: 'juegos-seguros-infantiles', component: SylosComponent },
  { path: 'juegos-seguros-infantiles/:juegos-seguros-infantiles', component: SylosComponent },
  { path: 'juegos-seguros-online', component: SylosComponent },
  { path: 'juegos-seguros-online/:juegos-seguros-online', component: SylosComponent },
  { path: 'juegos-seguros-educativos', component: SylosComponent },
  { path: 'juegos-seguros-educativos/:juegos-seguros-educativos', component: SylosComponent },

  { path: 'juegos-de-ninos', component: SylosComponent },
  { path: 'juegos-de-ninos/:juegos-de-ninos', component: SylosComponent },
  { path: 'juegos-ninos', component: SylosComponent },
  { path: 'juegos-ninos/:juegos-ninos', component: SylosComponent },
  { path: 'juegos-faciles-para-ninos', component: SylosComponent },
  { path: 'juegos-faciles-para-ninos/:juegos-faciles-para-ninos', component: SylosComponent },
  { path: 'juegos-para-ninos-gratis', component: SylosComponent },
  { path: 'juegos-para-ninos-gratis/:juegos-para-ninos-gratis', component: SylosComponent },
  { path: 'juegos-para-ninos-de-4', component: SylosComponent },
  { path: 'juegos-para-ninos-de-4/:juegos-para-ninos-de-4', component: SylosComponent },
  { path: 'juegos-para-ninos-pequenos', component: SylosComponent },
  { path: 'juegos-para-ninos-pequenos/:juegos-para-ninos-pequenos', component: SylosComponent },
  { path: 'juegos-divertidos-para-ninos', component: SylosComponent },
  { path: 'juegos-divertidos-para-ninos/:juegos-divertidos-para-ninos', component: SylosComponent },
  { path: 'juegos-de-memoria-para-ninos', component: SylosComponent },
  { path: 'juegos-de-memoria-para-ninos/:juegos-de-memoria-para-ninos', component: SylosComponent },
  { path: 'juegos-de-ninos-para-aprender', component: SylosComponent },
  { path: 'juegos-de-ninos-para-aprender/:juegos-de-ninos-para-aprender', component: SylosComponent },
  { path: 'juegos-online-para-ninos', component: SylosComponent },
  { path: 'juegos-online-para-ninos/:juegos-online-para-ninos', component: SylosComponent },

  { path: 'juegos-para-aprender', component: SylosComponent },
  { path: 'juegos-para-aprender/:juegos-para-aprender', component: SylosComponent },
  { path: 'juegos-para-aprender-a-leer', component: SylosComponent },
  { path: 'juegos-para-aprender-a-leer/:juegos-para-aprender-a-leer', component: SylosComponent },
  { path: 'juegos-divertidos-para-aprender', component: SylosComponent },
  { path: 'juegos-divertidos-para-aprender/:juegos-divertidos-para-aprender', component: SylosComponent },
  { path: 'juegos-para-aprender-historia', component: SylosComponent },
  { path: 'juegos-para-aprender-historia/:juegos-para-aprender-historia', component: SylosComponent },
  { path: 'juegos-para-aprender-musica', component: SylosComponent },
  { path: 'juegos-para-aprender-musica/:juegos-para-aprender-musica', component: SylosComponent },
  { path: 'juegos-para-aprender-ciencias', component: SylosComponent },
  { path: 'juegos-para-aprender-ciencias/:juegos-para-aprender-ciencias', component: SylosComponent },
  { path: 'juegos-para-aprender-arte', component: SylosComponent },
  { path: 'juegos-para-aprender-arte/:juegos-para-aprender-arte', component: SylosComponent },
  { path: 'juegos-para-aprender-espanol', component: SylosComponent },
  { path: 'juegos-para-aprender-espanol/:juegos-para-aprender-espanol', component: SylosComponent },
  { path: 'juegos-para-aprender-valores', component: SylosComponent },
  { path: 'juegos-para-aprender-valores/:juegos-para-aprender-valores', component: SylosComponent },
  { path: 'juegos-para-aprender-y-practicar-matematicas', component: SylosComponent },
  { path: 'juegos-para-aprender-y-practicar-matematicas/:juegos-para-aprender-y-practicar-matematicas', component: SylosComponent },

  { path: 'juegos-educativos', component: SylosComponent },
  { path: 'juegos-educativos/:juegos-educativos', component: SylosComponent },
  { path: 'juegos-educativos-en-linea', component: SylosComponent },
  { path: 'juegos-educativos-en-linea/:juegos-educativos-en-linea', component: SylosComponent },
  { path: 'juegos-educativos-por-internet', component: SylosComponent },
  { path: 'juegos-educativos-por-internet/:juegos-educativos-por-internet', component: SylosComponent },
  { path: 'juegos-educativos-online', component: SylosComponent },
  { path: 'juegos-educativos-online/:juegos-educativos-online', component: SylosComponent },
  { path: 'juegos-educativos-para-ninos', component: SylosComponent },
  { path: 'juegos-educativos-para-ninos/:juegos-educativos-para-ninos', component: SylosComponent },
  { path: 'juegos-educativos-gratis', component: SylosComponent },
  { path: 'juegos-educativos-gratis/:juegos-educativos-gratis', component: SylosComponent },
  { path: 'juegos-educativos-infantiles', component: SylosComponent },
  { path: 'juegos-educativos-infantiles/:juegos-educativos-infantiles', component: SylosComponent },

  { path: 'juegos-para-ninos', component: SylosNinoComponent },
  { path: 'juegos-para-ninos/:juegos-para-ninos', component: SylosNinoComponent },

  { path: 'gracias_maestros', component: SylosGraciasComponent },
  { path: 'gracias_ninos/:username', component: SylosGraciasComponent },

  { path: '**', redirectTo: '/home' },
];

@NgModule({
  /*imports: [RouterModule.forRoot(routes, { useHash: true })],*/
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
