import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GameService } from '../service/game.service';
import { UserService } from '../service/user.service';
import { UserIdleService } from 'angular-user-idle';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  
  private token: string = localStorage.getItem('token');
  private email: string = localStorage.getItem('email');

  private sections: any;
  public language;

  constructor(private router: Router, private gameService: GameService, private userService: UserService, private userIdle: UserIdleService) { 
    this.language = this.userService.getLanguage();
  }
  kill(element, id) {
    $(element).modal('hide');
    let iframe = document.getElementById(id) as HTMLIFrameElement;
    let src = $(iframe).attr('src');  
    console.log(src);    
    $(iframe).attr('src', '').attr('src', src);
  }
  ngOnInit() {
    setTimeout(function () {
      // Second level
      $(".js-second-level").on('click', function (e) {
        e.preventDefault();
        $(this).next("ol").toggleClass("active");
        $(this).toggleClass("active");
      });
      //--- Open
      $("#open").click(function (e) {
        e.preventDefault();
        $(this).toggleClass("active");
        $(".menu").toggleClass("active");
      });
    }, 1800);

    this.sections = this.userService.getSecciones();
    
    //Start watching for user inactivity.
    this.userIdle.startWatching();
    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe();
    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() => this.exit());

  }
  stop() {
    this.userIdle.stopTimer();
  }

  stopWatching() {
    this.userIdle.stopWatching();
  }

  startWatching() {
    this.userIdle.startWatching();
  }

  restart() {
    this.userIdle.resetTimer();
  }

  exit(){
    let token2 = localStorage.getItem('token');
    localStorage.removeItem('token');
    if(token2!=null) this.gameService.salir(token2, this.email).subscribe();
    this.router.navigate(['home']);
  }

}
