import { Component, OnInit } from '@angular/core';
import { DetalleService } from '../service/detalle.service';
import { Router } from '@angular/router';
import { Navegacion } from '../models/navigate';
import { UserService } from '../service/user.service';
import { UserIdleService } from 'angular-user-idle';
import { GameService } from '../service/game.service';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
declare var $;

@Component({
  selector: 'app-new-home',
  templateUrl: './new-home.component.html',
  styleUrls: ['./new-home.component.css']
})
export class NewHomeComponent implements OnInit {
  
  private token: string = localStorage.getItem('token');
  private email: string = localStorage.getItem('email');
  public navegacion = new  Navegacion();
  public src = '';
  public language = '';

  private sections: any;

  constructor(private detalleService: DetalleService, private router: Router, private userService: UserService, 
      private gameService: GameService,  private userIdle: UserIdleService, public translateS: TranslateService) { 
    this.language = userService.getLanguage();
    this.navegacion.seccion = 1;
    this.detalleService.navegacion(this.token,this.navegacion).subscribe();

    this.userService.checklicense(this.token).subscribe((response: any) => {
        if(response.message!='Ok'){
          Swal.fire({
            html: '<br><img src="assets/images/logo/labtak_logo.svg" alt="LabTak" width="150px"><br><br><br><h3> '+ response.message + '</h3><br>',
            showCancelButton: true,
            confirmButtonColor: '#0098CE',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Actualizar',
            cancelButtonText:'Cancelar'
          }).then((result) => {
              if (result.value) {
                this.router.navigate(['perfil']);
              }
          });
        }
      });
  }

  ngOnInit() {
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
    this.sections = this.userService.getSecciones();

    //Start watching for user inactivity.
    this.userIdle.startWatching();
    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe();
    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() => this.exit());
  }
  stop() {
    this.userIdle.stopTimer();
  }

  stopWatching() {
    this.userIdle.stopWatching();
  }

  startWatching() {
    this.userIdle.startWatching();
  }

  restart() {
    this.userIdle.resetTimer();
  }

  exit(){
    let token2 = localStorage.getItem('token');
    localStorage.removeItem('token');
    if(token2!=null) this.gameService.salir(token2, this.email).subscribe();
    this.router.navigate(['home']);
  }

  kill(element, id) {
    $(element).modal('hide');
    this.src = '';
  }

  aviso(){
     Swal.fire({
      html: '<br><h3>'+ this.translateS.instant('inicio.inicio_acceso')+' <a href="mailto:labtak@inoma.mx">labtak@inoma.mx</a>'+ this.translateS.instant('inicio.inicio_acceso1') +'</h3><br>',
      showCancelButton: false,
      showConfirmButton: false,
      timer: 6000
    })
  }

}
