export class User {
    constructor(
        public email: string,
        public password: string,
        public is_email = 0,
        public taktaktak = 0,
        public emgm = 0,
        public colombia = 0,
        public fya = 0,
        public datacole = 0,
        public piloto = 0,
        public sek = 0,
        public techint = 0,
        public proed = 0,
        public activar_licencia = 0,
        public codigo = ""
    ) { }
}
export class Registro {
    public email = "";
    public emailConfir = "";
    public name = "";
    public lastname = "";
    public password = "";
    public password_conf = "";
    public edad = "";
    public genero = "";
    public area_id = "";
    public pais_id = "";
    public terms = "";
    public type_form = " labtak";
    public user_rol_id = "";
    public txt_source = "";
    public captcha = "";
    public state_id = "";
    public invt_username = "";
    public phone = "";
    public aviso = "";
    public colombia = 0;
    public fya = 0;
    public codigofya = "";
    public taktaktak = 0;
    public emgm = 0;
    public datacole = 0;
    public piloto = 0;
    public sek = 0;
    public techint = 0;
    public proed = 0;
    public activar_licencia = 0;
    public codigo = "";
    public codigoproed = "";
}

export class UserTTT {
    public imgAvatar: string;
    public username: string;
    public password: string;
    public password_conf: string;
    public edad = "";
    public gender = "";
    public question_id = 0;
    public answer = "";
    public terms = false;
    public consent = false;
}

export class LoginTTT {
    public login_username: string;
    public login_password: string;
}

export class RecoverTTT {
    public id: number;
    public username: string;
    public slc_question = 0;
    public slc_answer = "";
    public password = "";
    public password_conf = "";
}