import { Component, OnInit } from '@angular/core';
import { Perfil} from '../models/perfil';
import { DetalleService} from '../service/detalle.service';
import {Foto} from '../models/perfil';
import Swal from 'sweetalert2';
import perfiles from 'src/assets/JSON/images.json';
import {Router} from '@angular/router';
import { GameService } from '../service/game.service';
import { Navegacion } from '../models/navigate';
import { TranslateService } from '@ngx-translate/core';

declare var $: any;

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.css']
})
export class PerfilComponent implements OnInit {
  public perfil = new Perfil();
  private token = localStorage.getItem('token');
  public imageUrl: string;
  public profileImages
  public  datosFoto = new Foto();
  public navegacion = new Navegacion();
  public estados;
  public paises;
  public ccts = [];
  public cctsMunicipio;
  public municipios;
  public section = 1;
  public notificaciones = [];
  public datosLicencia = [];

  public cctSelected;
  public municipio;

  TipoDocente: Array<any> = [
    { asignatura: 'Asistente', id: 19, status: 0 },
    { asignatura: 'De grupo', id: 18, staus: 0 },
    { asignatura: 'Educación artística', id: 15, staus: 0  },
    { asignatura: 'Educación física', id: 14, staus: 0  },
    { asignatura: 'Inglés', id: 13, staus: 0  },
    { asignatura: 'USAER', id: 20, staus: 0  },
  ];

  constructor(private detalleService:DetalleService, private router: Router, private gameService: GameService,  public translateS: TranslateService) {
    this.navegacion.seccion = 5;
    this.detalleService.catPais(this.token).subscribe((response:any)=>{
      this.paises=response;
    });
    this.detalleService.catState(this.token).subscribe((response:any)=>{
      this.estados=response;
    });
    this.detalleService.navegacion(this.token, this.navegacion).subscribe();
    this.profileImages=perfiles.images;
    // console.log(this.profileImages);
    this.detalleService.notificaciones(this.token).subscribe((response: any) => {
      this.notificaciones = response.notificaciones;
    });

    this.detalleService.detallePerfil(this.token).subscribe((response:any)=>{
      this.datosLicencia = response.licencia;
      // console.log(response.user_data);
      // console.log(response.user_data.id);
      this.perfil.name = response.user_data.name;
      this.perfil.lastname = response.user_data.lastname;
      this.perfil.user_id = response.user_data.id;
      this.perfil.genero = response.user_data.genero;
      this.perfil.edad = response.user_data.edad;
      this.perfil.area_id = response.user_data.area_id;
      this.perfil.pais_id = response.user_data.pais_id;
      this.perfil.state_id = response.user_data.state_id;
      this.perfil.user_rol_id = response.user_data.user_rol_id;
      this.perfil.email = response.user_data.email;
      this.perfil.username = response.user_data.username;
      this.perfil.has_license = response.user_data.has_license;
      this.perfil.img = response.user_data.img;
      this.perfil.cct_id = response.user_data.cct_id;
      if(this.perfil.user_rol_id != '1') this.ccts = response.cct_info;

      if(response.user_data.asignaturas!=null || response.user_data.asignaturas!= ''){
        let asig = response.user_data.asignaturas.split(',');  
        this.TipoDocente.forEach(td => {
          asig.forEach(element => {
            if(element == td.id) td.status = 1;
          });
        });
      }

    });
    
  }

  ngOnInit() {
    setTimeout(function () {
      $(".js-modal-cct").click(function (e) {
        e.preventDefault();
        $(".modal-cct").modal("show");
      });

      $(".perfil-menu").on('click', function (e) {
        e.preventDefault();
        var toggleClass = $(this).hasClass('active') ? true : false;
        $('.perfil-menu').removeClass('active');
        if (!toggleClass)
          $(this).toggleClass('active');
        // $(this).next("ul").toggleClass("active");
        // $(this).toggleClass("active");
      });

    }, 1500);
  }

  updatePerfil(form:any){
    this.TipoDocente.forEach(td => {
      if(td.status == 1){
        if(this.perfil.asignaturas == '') this.perfil.asignaturas = this.perfil.asignaturas + td.id;
        else this.perfil.asignaturas = this.perfil.asignaturas + ',' + td.id;
      }
    });
    // this.perfil.username=form.value.username;
    this.detalleService.updatePerfil(this.token, this.perfil).subscribe((response:any)=>{
      Swal.fire({
        type: 'success',
        title: this.translateS.instant('perfil.perfil_popup_01'),
        showConfirmButton: false,
        timer: 1500
      });
      // console.log("hecho");
    }, error =>{
        // Swal.fire({
        //   type: 'error',
        //   title: 'Oops...',
        //   text: 'Ocurrio un error al actualizar el usuario:' + error.error.message
        // });
      // console.log("nel");
    });
    //console.log(form.value.username);
  }

  updatePicture(name){
    this.datosFoto.picture= name;
    this.datosFoto.user_id =this.perfil.user_id;
    this.detalleService.editarFotoPerfil(this.token,this.datosFoto).subscribe((response:any)=>{
      this.detalleService.detallePerfil(this.token).subscribe((response: any) => {
        this.perfil.img = response.user_data.img;
      });
    });
  }
  exit(){
    localStorage.removeItem('token');
    this.router.navigate(['home']);  
    this.gameService.salir(this.token, this.perfil.email).subscribe(      
      data => {
        //console.log(data);
      }
    );
  }

  changeAsignaturas:any = (e, id) =>{
    this.TipoDocente.forEach(td => {
      if(td.id == id){
        if(e.checked) td.status = 1;
        else td.status = 0;
      }
    });
  }

  showcct(){
    $(".modal-cct").modal("show");
    this.detalleService.catMunicipio(this.token, this.perfil.state_id).subscribe((response: any) => {
        this.municipios = response;
    });
  }
  
  ngAfterViewInit() {
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }

  handleCCT:any = (e) => {
    this.detalleService.catCCTState(this.token, {estado: this.perfil.state_id, municipio: this.municipio}).subscribe((response: any) => {
      this.cctsMunicipio = response;
    });
  }

  agregar(){
    this.detalleService.addCCT(this.token, {id_user: this.perfil.user_id, id_cct: this.cctSelected}).subscribe((response: any) => {
      this.cctsMunicipio.forEach(c => {
        if(c.id == this.cctSelected) this.ccts.push(c);
      });
      Swal.fire({
        type: 'success',
        title: this.translateS.instant('perfil.perfil_popup_02'),
        showConfirmButton: false,
        timer: 1500
      });
    });
  }

  eliminarcct(cctdelete){
    let id_delete = (cctdelete.control) ? cctdelete.id : cctdelete.id_cct;
    Swal.fire({
      html: '<h3> '+this.translateS.instant('perfil.perfil_popup_03')+'</h3>',
      showCancelButton: true,
      confirmButtonColor: '#0098CE',
      cancelButtonColor: '#d33',
      confirmButtonText:  this.translateS.instant('perfil.perfil_popup_04'),
      cancelButtonText: this.translateS.instant('perfil.perfil_popup_05')
    }).then((result) => {
        if (result.value) {
          this.detalleService.deleteCCT(this.token, {id_user: this.perfil.user_id, id_cct: id_delete}).subscribe((response: any) => {
            this.ccts.forEach((c, index) => {
              if(c.id_cct == id_delete) this.ccts.splice(index, 1);
            });
            Swal.fire({
              type: 'success',
              title: this.translateS.instant('perfil.perfil_popup_06'),
              showConfirmButton: false,
              timer: 1500
            });
          });
        }
    });
  }


  changeSection(numb){
    this.section=numb;
  }

}
