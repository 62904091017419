import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DetalleService} from '../service/detalle.service';
import {Vincular} from '../models/grupos';
import * as XLSX from 'xlsx';
import { Navegacion } from '../models/navigate';
import Swal from 'sweetalert2';
import { UserService } from '../service/user.service';
import { TranslateService } from '@ngx-translate/core';

declare var $:any;

@Component({
  selector: 'app-editar-grupo',
  templateUrl: './editar-grupo.component.html',
  styleUrls: ['./editar-grupo.component.css']
})
export class EditarGrupoComponent implements OnInit {
  private token = localStorage.getItem('token');
  public vincular = new Vincular();
  public grado;
  public grupo;
  public teacher;
  public access;
  public alumnos;
  public planes;
  public grupos;
  public gg;
  public color;
  public grupoNombre;
  public navegacion = new Navegacion();
  private fileToUpload :any;
  private archivoName ='';

  private sections: any;

  constructor(
              private detalleService:DetalleService, public translateS: TranslateService,
              private activatedRoute: ActivatedRoute, private router: Router,  private userService: UserService) {
    this.navegacion.seccion = 11;
    this.detalleService.navegacion(this.token, this.navegacion).subscribe();
    this.activatedRoute.params.subscribe((params:any)=>{
      //console.log(params);
      this.grado =params.grado;
      this.grupo=params.grupo;
      this.gg=this.grado + this.grupo;
      this.teacher=params.id;
      this.access=this.teacher+this.grado+this.grupo;
      //console.log(this.access)
      this.detalleService.listarALumnos(this.token,this.access).subscribe((response:any)=>{
        console.log('response.lista',response.lista);
        this.alumnos=response.lista;
      });
      this.detalleService.planesofGrupo(this.token,this.access).subscribe((response:any)=>{
        //console.log(response);
        this.planes=response.planes;
      });
      
    });
    this.detalleService.grupos(this.token).subscribe((response: any) => {
      this.grupos = response.grupos;
      //console.log(this.grupos);
      this.grupos.forEach(g => {
        if(g.access_link == this.access)  this.grupoNombre = g.name_group;
      });
    });
   }

  ngOnInit() {

    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
    this.sections = this.userService.getSecciones();
    this.archivoName = "Subir archivo"
    $(".js-group-list").click(function (e) {
      e.preventDefault();
      $(this).toggleClass("active");
      $(this).next(".drop-group").stop().slideToggle();
    });

    this.detalleService.grupos(this.token).subscribe((response: any) => {
      this.grupos = response.grupos;
      //console.log(this.grupos);
    });
  }
  desvincular(idUser){
    this.detalleService.desvicnularStudent(this.token,this.access,idUser).subscribe((response:any)=>{
      //console.log(response);
      Swal.fire({
        type: 'success',
        title: this.translateS.instant('grupoDetalle.grupo_det_ts_01'),
        showConfirmButton: false,
        timer: 1500
      });
      this.detalleService.listarALumnos(this.token, this.access).subscribe((response: any) => {
        //console.log(response.lista);
        this.alumnos = response.lista;
      });
    });
  }
  onSubmit(form){
    //console.log(form.value);
    if(this.vincular.fullname!="" && form.value){
      this.vincular=form.value;
      this.vincular.grado=this.grado;
      this.vincular.grupo = this.grupo;
      //console.log(this.vincular);
      this.detalleService.addStudentToGroup(this.token,this.vincular).subscribe((response:any)=>{
        //console.log(response);
        Swal.fire({
          type: 'success',
          title: this.translateS.instant('grupoDetalle.grupo_det_ts_02'),
          showConfirmButton: false,
          timer: 1500
        });
        this.vincular.username="";
        this.vincular.fullname="";
        this.vincular.password="";
        this.detalleService.listarALumnos(this.token, this.access).subscribe((response: any) => {
          //console.log(response.lista);
          this.alumnos = response.lista;
        });
      }, error => {
        Swal.fire({
          type: 'error',
          title: error.error.message,
          showConfirmButton: false,
          timer: 4000
        });
      });
    }
    else{
      Swal.fire({
        type: 'error',
        title: this.translateS.instant('grupoDetalle.grupo_det_ts_03'),
        showConfirmButton: false,
        timer: 4000
      });
    }

  }
  newGroup(gra, gru){
    //console.log(gru)
    //console.log(gra)
    this.grupo=gru;
    this.grado=gra;
    this.gg = this.grado + this.grupo;
    this.access = this.teacher + this.grado + this.grupo;
    //console.log(this.access)
    this.detalleService.listarALumnos(this.token, this.access).subscribe((response: any) => {
      //console.log(response.lista);
      this.alumnos = response.lista;
    });
    this.detalleService.planesofGrupo(this.token, this.access).subscribe((response: any) => {
      //console.log(response);
      this.planes = response.planes;
    });
    this.detalleService.grupos(this.token).subscribe((response: any) => {
      this.grupos = response.grupos;
      //console.log(this.grupos)
      this.grupos.forEach(g => {
        if(g.access_link == this.access)  this.grupoNombre = g.name_group;
      });
    });

  }
  imprimirLista(){
    const listado: any []=[];
    listado.push(['NOMBRE ','USUARIO','CONTRASEÑA']);
    for(let i in this.alumnos){
      listado.push([this.alumnos[i].fullname, this.alumnos[i].username, this.alumnos[i].txt_password]);
    }

    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(listado);

    const wb: XLSX.WorkBook =XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb,ws,'Lista de Alumnos');

    XLSX.writeFile(wb,'Lista.xlsx');
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    this.archivoName = this.fileToUpload.name;
    console.log(this.fileToUpload);
  }

  subirArchivo(){
    if(this.fileToUpload != undefined &&
     this.fileToUpload.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"){
      var formData: FormData = new FormData();
      formData.append('excel', this.fileToUpload, this.fileToUpload.name);
      formData.append('grupo', this.grupo);
      formData.append('grado', this.grado);
      formData.append('token', this.token);
      Swal.showLoading();
      this.detalleService.postVinculacionWithExcel(formData).subscribe((response: any) => {
        Swal.close();
        let textErr = response.toString();
        Swal.fire(
          this.translateS.instant('grupoDetalle.grupo_det_ts_04'),
          this.translateS.instant('grupoDetalle.grupo_det_ts_05')+textErr,
          'success'
        );
        this.detalleService.listarALumnos(this.token, this.access).subscribe((response: any) => {
          this.alumnos = response.lista;
        });
      }, error => {
        setTimeout(() => {
          Swal.close();
          this.detalleService.listarALumnos(this.token, this.access).subscribe((response: any) => {
            this.alumnos = response.lista;
          });
        }, 10000);
      });

    }else{
      Swal.fire(
        this.translateS.instant('grupoDetalle.grupo_det_ts_06'),
        this.translateS.instant('grupoDetalle.grupo_det_ts_07'),
        'error'
      );
    }
  } 
}
