import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { Password } from '../models/perfil';
import { UserService } from '../service/user.service';

@Component({
  selector: 'app-recupera-contra',
  templateUrl: './recupera-contra.component.html',
  styleUrls: ['./recupera-contra.component.css']
})
export class RecuperaContraComponent implements OnInit {

  public confPass = '';
  public newPassword = new Password();
  public fecha;
  public language;

  constructor(private userService: UserService, private activatedRoute: ActivatedRoute, private router: Router) {
    this.activatedRoute.params.subscribe(params => {
      this.newPassword.id = params.id;
      this.fecha = parseInt(params.fecha);
    });
  }

  ngOnInit() {
  }

  recuperar(form:any){
    let ahora = new Date();
    let tiempo = ahora.getTime();
    console.log(tiempo, this.fecha);
    if(tiempo <= this.fecha){
      if(this.newPassword.password === this.confPass){
        this.userService.recuperarContraseña(this.newPassword).subscribe((response:any)=>{
          this.language = localStorage.getItem('language');
          localStorage.clear();
          localStorage.setItem('language',this.language);
          this.newPassword.id='';
          this.newPassword.password='';
        
          Swal.fire({
            type: 'success',
            title: 'Contraseña actualizada.',
            showConfirmButton: false,
            timer: 1500
          });
          this.router.navigate(['login']);
        });
      }else{
        Swal.fire({
            type: 'error',
            title: 'Las contraseñas no coinciden.',
            showConfirmButton: false,
            timer: 1500
          });
      }
    } else{
      Swal.fire({
        type: 'error',
        title: 'Tu tiempo para modificar tu contraseña expiró. Vuelve a solicitar tu cambio de contraseña.',
        showConfirmButton: false,
        timer: 1500
      });
    }
  }

}
