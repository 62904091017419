import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-header-lang',
  templateUrl: './header-lang.component.html',
  styleUrls: ['./header-lang.component.css']
})
export class HeaderLangComponent implements OnInit {

  @Input() language: string = 'es';
  @Output() changeLanguage: EventEmitter<string> = new EventEmitter();

  constructor() { }

  useLanguage(language: string) {
    this.changeLanguage.emit( language );
  }

  ngOnInit() {
  }

}
